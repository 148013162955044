














import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class StudentHistoryHeader extends Vue {
  get isActive(): any {
    return function (target: string) {
      // @ts-ignore
      const { path } = this.$route
      const active = path.split('/').slice(-1)[0]
      return {
        active: target === active,
      }
    }
  }
}
